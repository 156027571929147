import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { Box } from 'rebass';
import AnimatedContainer from './AnimatedContainer';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        background: file(relativePath: { eq: "management_cover.png" }) {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={({ background }) => (
      <AnimatedContainer>
        <Box
          css={{
            maxWidth: '1500px',
          }}
          mx="auto"
          px={[20, 30, 70]}
          mt={[35, 70]}
          mb={[25, 30, 70]}
        >
          <Img
            fluid={background.childImageSharp.fluid}
            style={{
              minHeight: '335px',
            }}
          />
        </Box>
      </AnimatedContainer>
    )}
  />
);
