import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { useScrollYPosition } from 'react-use-scroll-position';
import { Box, Flex, Text } from 'rebass';
import { Fixed } from './Position';

const Banner = ({ invertColor }) => {
  const { bannerData } = useStaticQuery(graphql`
    query {
      bannerData: bannerJson {
        enabled
        title
        url
      }
    }
  `);

  const scrollY = useScrollYPosition();

  if (!bannerData.enabled) {
    return null;
  }

  return (
    <Fixed
      bottom={0}
      css={{
        transform: scrollY === 0 ? 'translateY(0)' : 'translateY(150px)',
        transition: 'transform 0.3s ease',
        zIndex: 5000,
      }}
      width="100%"
    >
      <Box
        bg={invertColor ? 'white' : 'black'}
        color={invertColor ? 'black' : 'white'}
      >
        <Flex
          alignItems="center"
          as="a"
          color={invertColor ? 'black' : 'white'}
          css={{
            maxWidth: '1500px',
            textDecoration: 'none',
          }}
          href={bannerData.url}
          justifyContent="space-between"
          mx="auto"
          px={[20, 30, 70]}
          py={20}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Text
            fontFamily="grot10"
            fontSize={['16px', '16px', '21px']}
            letterSpacing="-0.5px"
          >
            {bannerData.title}
          </Text>
          <Box>
            <svg width="17" height="10" viewBox="0 0 17 10">
              <path
                fill={invertColor ? '#000000' : '#ffffff'}
                fillRule="evenodd"
                d="M11,4 L11,0 L17,5 L11,10 L11,6 L1.70530257e-13,6 L1.70530257e-13,4 L11,4 Z"
              />
            </svg>
          </Box>
        </Flex>
      </Box>
    </Fixed>
  );
};

Banner.propTypes = {
  invertColor: PropTypes.bool,
};

Banner.defaultProps = {
  invertColor: false,
};

export default Banner;
