import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import AnimatedContainer from './AnimatedContainer';
import Hide from './Hide';

const List = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: 15px;
`;

const ArtistLink = ({ artistName }) => (
  <Text
    as="a"
    css={{
      '&:hover': {
        textDecorationColor: 'black',
      },
      textDecoration: 'underline',
      textDecorationColor: 'transparent',
      transition: 'all 0.1s ease-in-out',
    }}
    color="black"
    fontFamily="grot10"
    fontSize={['16px', '16px', '21px']}
    href={`#${_.snakeCase(artistName)}`}
    letterSpacing="-0.5px"
  >
    {artistName}
  </Text>
);

ArtistLink.propTypes = {
  artistName: PropTypes.string.isRequired,
};

const BookingArtistsNav = ({ artists }) => (
  <AnimatedContainer>
    <Box
      css={{
        maxWidth: '1500px',
      }}
      mx="auto"
      px={[20, 30, 70]}
    >
      <Hide display={['none', 'none', 'block']}>
        <Flex
          css={{
            borderTop: '1px solid black',
          }}
          flexWrap="wrap"
          pt={15}
          pb={80}
        >
          <Text fontFamily="sans" fontSize={19} width={1 / 4}>
            Artists
          </Text>
          {_.chunk(artists, Math.ceil(artists.length / 3)).map(
            (artistsGroup, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={index} width={1 / 4}>
                <List>
                  {artistsGroup.map((artist) => (
                    <li key={artist.title}>
                      <ArtistLink artistName={artist.title} />
                    </li>
                  ))}
                </List>
              </Box>
            ),
          )}
        </Flex>
      </Hide>
      <Hide display={['block', 'block', 'none']}>
        <Flex
          css={{
            borderTop: '1px solid black',
          }}
          flexWrap="wrap"
          pt={10}
          pb={25}
        >
          <Text fontFamily="sans" fontSize={18} mb={15} width="100%">
            Artists
          </Text>
          {_.chunk(artists, Math.ceil(artists.length / 2)).map(
            (artistsGroup, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={index} width={1 / 2}>
                <List>
                  {artistsGroup.map((artist) => (
                    <li key={artist.title}>
                      <ArtistLink artistName={artist.title} />
                    </li>
                  ))}
                </List>
              </Box>
            ),
          )}
        </Flex>
      </Hide>
    </Box>
  </AnimatedContainer>
);

BookingArtistsNav.propTypes = {
  artists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default BookingArtistsNav;
