import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ToastContainer } from 'react-toastify';
import { Box } from 'rebass';
import Banner from '../components/Banner';
import BookingArtist from '../components/BookingArtist';
import BookingArtistsNav from '../components/BookingArtistsNav';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ManagementCover from '../components/ManagementCover';
import '../styles/plyr.css';

class Management extends PureComponent {
  render() {
    const {
      data: { artists: artistsData },
    } = this.props;

    const artists = artistsData.nodes;

    return (
      <div>
        <ToastContainer />
        <Banner />
        <Header activePageName="MANAGEMENT" rightText="DISCOVER OUR ARTISTS" />
        <ManagementCover />
        <div id="content">
          <BookingArtistsNav artists={artists} />
          <Box pb={['0', '0', '25px']}>
            {artists.map((artist) => (
              <BookingArtist artist={artist} isManagementPage key={artist.id} />
            ))}
          </Box>
        </div>
        <Footer activePageName="MANAGEMENT" />
      </div>
    );
  }
}

Management.propTypes = {
  data: PropTypes.shape({
    artists: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          biography: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
          managementAgents: PropTypes.arrayOf(
            PropTypes.shape({
              email: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
            }).isRequired,
          ).isRequired,
          managementPageAttributes: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string.isRequired,
              value: PropTypes.string.isRequired,
            }).isRequired,
          ).isRequired,
          musicWidget: PropTypes.shape({
            artistId: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
          }).isRequired,
          pressKitUrl: PropTypes.string,
          socialLinks: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              url: PropTypes.string.isRequired,
            }).isRequired,
          ).isRequired,
          title: PropTypes.string.isRequired,
          video: PropTypes.string,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    events: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          artists: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
          date: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
          place: PropTypes.shape({
            city: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      ).isRequired,
    }),
  }).isRequired,
};

export const query = graphql`
  {
    artists: allArtistsJson(
      sort: { fields: [title] }
      filter: { showOnManagementPage: { eq: true } }
    ) {
      nodes {
        id
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        biography
        bookingAgents {
          email
          title
        }
        managementAgents {
          email
          title
        }
        bookingPageAttributes {
          title
          value
        }
        managementPageAttributes {
          title
          value
        }
        pressKitUrl
        musicWidget {
          type
          artistId
        }
        socialLinks {
          name
          url
        }
        youtubeIframeUrl
      }
    }
  }
`;

export default Management;
